export const filters = {
  parking: [
    {
      id: 1,
      title: 'search.filters.parking-type',
      name: 'valet-parking',
      translation: 'general.valet-parking',
      ssf: 'valet-parking:on',
      param: 'valet-parking',
      value: false,
      condition: (val) => val.parking_type === 'valet',
    },
    {
      id: 1,
      name: 'shuttle-parking',
      ssf: 'shuttle:on',
      param: 'shuttle',
      translation: 'general.shuttle-parking',
      value: false,
      condition: (val) => val.parking_type === 'shuttle',
    },
  ],
  location: [
    {
      id: 2,
      title: 'search.filters.location',
      name: 'outdoor-parking',
      ssf: 'outside:on',
      param: 'outside',
      translation: 'templates.outdoor-parking',
      value: false,
      condition: (val) => val.roof === false,
    },
    {
      id: 2,
      name: 'indoor-parking',
      ssf: 'inside:on',
      param: 'inside',
      translation: 'templates.indoor-parking',
      value: false,
      condition: (val) => val.roof === true,
    },
  ],
  carKeys: [
    {
      id: 3,
      title: 'search.filters.car-keys',
      name: 'keep-keys',
      ssf: 'keepKeys:on',
      param: 'keepKeys',
      translation: 'templates.keep-keys',
      value: false,
      condition: (val) => val.properties.key_extend === false,
    },
    {
      id: 3,
      name: 'do-not-keep-keys',
      ssf: 'doNotKeepKeys:on',
      param: 'doNotKeepKeys',
      translation: 'templates.do-not-keep-keys',
      value: false,
      condition: (val) => val.properties.key_extend === true,
    },
  ],
  payOptions: [
    {
      id: 4,
      title: 'search.filters.payment-options',
      name: 'pay-on-location',
      ssf: 'offline:on',
      param: 'offline',
      translation: 'search.pay-on-location',
      value: false,
      condition: (val) => val.merchant?.payment?.offline,
    },
    {
      id: 4,
      name: 'pay-at-parkos',
      ssf: 'online:on',
      param: 'online',
      translation: 'search.pay-at-parkos',
      value: false,
      condition: (val) => val.merchant?.payment?.online,
    },
  ],
  services: [
    {
      id: 5,
      title: 'search.filters.services',
      name: 'evParking',
      ssf: 'evParking:on',
      param: 'evParking',
      translation: 'search.ev-parking',
      value: false,
      condition: (val) => val.properties.ev_parking === true,
    },
    {
      id: 5,
      name: 'parkSleepFly',
      ssf: 'parkSleepFly:on',
      param: 'parkSleepFly',
      translation: 'search.park-sleep-fly',
      value: false,
      condition: (val) => val.properties.park_sleep_fly === true,
    },
  ],
  returnStation: [
    {
      id: 6,
      title: 'general.other-options',
      name: 'returnLocationOn',
      ssf: 'returnLocationOn:on',
      param: 'returnLocationOn',
      translation: 'search.other-return-location',
      value: false,
      condition: (val) => val.merchant?.properties?.return_at_different_airport,
    },
  ],
  official: [
    {
      id: 6,
      title: 'merchant.is_official_airport',
      name: 'officialAirportOn',
      ssf: 'officialAirportOn:on',
      param: 'officialAirportOn',
      translation: 'merchant.is_official_airport',
      value: false,
      condition: (val) => val.merchant?.properties?.official_airport || val?.pseudoOfficial,
    },
  ],
};

export const transportIcons = new Map([
  ['train', 'directions_transit'],
  ['bus', 'airport_shuttle'],
  ['walking', 'directions_walk'],
]);

export const transportLabels = new Map([
  ['train', 'search.exclusive-transfer'],
  ['bus', 'search.inclusive-transfer'],
  ['walking', 'templates.walk-distance'],
]);

export const selectOption = [
  {
    sortValue: 'price',
    val: 'price',
    i18n: 'search.sort-by-price-low',
    field: 'price',
  },
  {
    sortValue: 'price_desc',
    val: 'price_desc',
    i18n: 'search.sort-by-price-high',
    field: 'price',
  },
  {
    sortValue: 'sort_distance',
    val: 'distance',
    field: 'sort_distance',
    i18n: 'search.sort-by-closest',
  },
  {
    sortValue: 'sort_reviews',
    val: 'reviews',
    field: 'sort_revivews',
    i18n: 'search.sort-by-best-reviews',
  },
];

export const twelveTimeArray = [
  '12:00 AM',
  '12:15 AM',
  '12:30 AM',
  '12:45 AM',
  '1:00 AM',
  '1:15 AM',
  '1:30 AM',
  '1:45 AM',
  '2:00 AM',
  '2:00 AM',
  '2:15 AM',
  '2:30 AM',
  '2:45 AM',
  '3:00 AM',
  '3:15 AM',
  '3:30 AM',
  '3:45 AM',
  '4:00 AM',
  '4:15 AM',
  '4:30 AM',
  '4:45 AM',
  '5:00 AM',
  '5:15 AM',
  '5:30 AM',
  '5:45 AM',
  '6:00 AM',
  '6:15 AM',
  '6:30 AM',
  '6:45 AM',
  '7:00 AM',
  '7:15 AM',
  '7:30 AM',
  '7:45 AM',
  '8:00 AM',
  '8:15 AM',
  '8:30 AM',
  '8:45 AM',
  '9:00 AM',
  '9:15 AM',
  '9:30 AM',
  '9:45 AM',
  '10:00 AM',
  '10:15 AM',
  '10:30 AM',
  '10:45 AM',
  '11:00 AM',
  '11:15 AM',
  '11:30 AM',
  '11:45 AM',
  '12:00 PM',
  '12:15 PM',
  '12:30 PM',
  '12:45 PM',
  '1:00 PM',
  '1:15 PM',
  '1:30 PM',
  '1:45 PM',
  '2:00 PM',
  '2:00 PM',
  '2:15 PM',
  '2:30 PM',
  '2:45 PM',
  '3:00 PM',
  '3:15 PM',
  '3:30 PM',
  '3:45 PM',
  '4:00 PM',
  '4:15 PM',
  '4:30 PM',
  '4:45 PM',
  '5:00 PM',
  '5:15 PM',
  '5:30 PM',
  '5:45 PM',
  '6:00 PM',
  '6:15 PM',
  '6:30 PM',
  '6:45 PM',
  '7:00 PM',
  '7:15 PM',
  '7:30 PM',
  '7:45 PM',
  '8:00 PM',
  '8:15 PM',
  '8:30 PM',
  '8:45 PM',
  '9:00 PM',
  '9:15 PM',
  '9:30 PM',
  '9:45 PM',
  '10:00 PM',
  '10:15 PM',
  '10:30 PM',
  '10:45 PM',
  '11:00 PM',
  '11:15 PM',
  '11:30 PM',
  '11:45 PM',
];

export const priceAggregationInterval = Object.freeze({
  daily: {
    value: 'daily',
    translation: 'templates.price-per-day',
  },
  weekly: {
    value: 'weekly',
    translation: 'general.weekly',
  },
});
