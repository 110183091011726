var render = function render(){var _vm=this,_c=_vm._self._c;return _c('header',{staticClass:"relative leading-normal bg-blue-600"},[_c('ReferralCashback'),_vm._v(" "),_c('div',{ref:"highlightElement",staticClass:"bg-blue-600"},[_c('section',{staticClass:"md:container md:px-10 z-40 top-menu md:h-auto md:static md:py-3 bg-blue-600 md:bg-transparent w-full md:flex flex-col md:flex-row md:items-center",class:{
        //   'top-menu--collapse': collapse,
        //   'top-menu--scrolled': scrollTop,
        'h-full': !_vm.collapse,
        flex: !_vm.collapse,
        fixed: !_vm.isRedesignSearchComponentv2 || !_vm.collapse,
      }},[_c('div',{staticClass:"py-[6.5px] header-logo-container w-full md:py-0 md:w-auto flex place-content-between px-3 md:px-0 transition-all duration-200",class:{
          // 'py-1': scrollTop && collapse,
          // 'py-[6.5px]': !collapse || !scrollTop,
        }},[_c('div',{staticClass:"flex items-center"},[_c('a',{staticClass:"top-menu__logo-wrapper p-3 md:-ml-3",attrs:{"href":"/"},on:{"click":_vm.clear}},[_c('Logo')],1),_vm._v(" "),_c('PartnerLogo')],1),_vm._v(" "),_c('div',{staticClass:"inline-flex items-center"},[_c('a',{attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('IconUser',{staticClass:"mr-3 md:hidden w-5 h-5"})],1),_vm._v(" "),_c('ToggleMenu',{staticClass:"top-menu__toggle-menu md:hidden",attrs:{"open":!_vm.collapse},on:{"toggled":_vm.toggleMenu}})],1)]),_vm._v(" "),_c('nav',{staticClass:"text-xl md:text-base w-screen header-nav h-0 flex-1 flex flex-col md:static top-36 left-0 right-0 bg-white md:bg-transparent overscroll-contain",class:{
          'header-nav--collapse': _vm.collapse,
          'header-nav--mega': _vm.megaMenuOpen,
        }},[_c('div',{ref:"scroller",staticClass:"h-full md:overflow-y-visible w-full flex flex-col md:static md:flex-row md:items-center items-stretch overscroll-contain",class:{
            'overflow-y-scroll': !_vm.isSearchActive && !_vm.languagesExpanded,
            relative: _vm.isSearchActive || _vm.languagesExpanded,
          }},[_c('ul',{staticClass:"header-nav__menu md:mx-auto flex md:flex-row flex-col min-h-max",class:{
              'header-nav__menu--toggled': _vm.megaMenuOpen,
              'hidden md:flex': _vm.languagesExpanded,
            }},[_c('li',{staticClass:"header-nav__menu__item",class:{ 'header-nav__menu__item--sub': _vm.megaMenuOpen }},[_c('div',{staticClass:"text-black md:text-white w-full md:w-auto"},[_c('LazyHydrate',{attrs:{"when-idle":""}},[_c('MegaMenu',{attrs:{"open":_vm.megaMenuOpen,"expression":_vm.searchExpression},on:{"toggled":_vm.megaMenuToggled}})],1)],1)]),_vm._v(" "),(_vm.zenDeskLangCode)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":`https://parkos.zendesk.com/hc/${_vm.zenDeskLangCode}`,"rel":"nofollow"},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                  "+_vm._s(_vm.$i18n('general.customer-service'))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e(),_vm._v(" "),(_vm.aboutPageLink)?_c('li',{staticClass:"header-nav__menu__item"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.aboutPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                  "+_vm._s(_vm.aboutPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e()]),_vm._v(" "),_c('ul',{staticClass:"header-nav__menu header-nav__menu--abs flex md:flex-row flex-col",class:{
              'header-nav__menu--sub': _vm.languagesExpanded,
              'hidden md:flex': _vm.megaMenuOpen,
            }},[_c('li',{staticClass:"header-nav__menu__item header-nav__menu__item--login"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":"/login/"},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                  "+_vm._s(_vm.$i18n('templates.header-login'))+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]),_vm._v(" "),(_vm.faqMeta)?_c('li',{staticClass:"header-nav__menu__item pr-0 header-nav__menu__item--meta"},[_c('a',{staticClass:"header-nav__menu__item__link",attrs:{"href":_vm.faqPageLink},on:{"click":_vm.clear}},[_c('div',{staticClass:"shrink-0 flex-1 w-full whitespace-nowrap text-ellipsis overflow-hidden mr-2"},[_vm._v("\n                  "+_vm._s(_vm.faqPageTitle)+"\n                ")]),_vm._v(" "),_c('IconCaretRight',{staticClass:"ml-auto text-black-alt-500 block md:hidden"})],1)]):_vm._e(),_vm._v(" "),_c('li',{staticClass:"header-nav__menu__item pr-0",class:{
                'header-nav__menu__item--toggled': _vm.languagesExpanded,
              }},[_c('MobileLanguages',{staticClass:"block md:hidden",attrs:{"expanded":_vm.languagesExpanded},on:{"toggled":_vm.languagesToggled}}),_vm._v(" "),_c('Languages',{staticClass:"font-heading hidden md:flex items-center"})],1)])])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }