export function setClock(date: Date, timeString: String) {
    const newDate = new Date(date)
    const [hours, minutes] = timeString.split(':')
    newDate.setHours(Number(hours), Number(minutes), 0, 0)
    return newDate
}

export function formatApiDate(date: Date) {
    let day = date.getDate().toString()
    if (date.getDate() < 10) {
        day = `0${day}`
    }
    let month = (date.getMonth() + 1).toString()
    if (date.getMonth() + 1 < 10) {
        month = `0${month}`
    }
    return `${date.getFullYear()}-${month}-${day}`
}
export function dateDiffMS(dateStart: Date, dateEnd: Date) {
    return dateEnd.getTime() - dateStart.getTime()
}
export function addMinutes(date: Date, minutes: number, roundUp15 = true) {
    let d = new Date(date.getTime() + minutes * 60000)
    if (roundUp15) {
        const min = Math.ceil((d.getMinutes()) / 15) * 15
        d = new Date(d.setMinutes(min))
    }
    return d
}
export function setFromISOString(date: Date, isoString: String) {
    const newDate = new Date(date)
    const [datePart] = isoString.split('T')
    const [years, months, days] = datePart.split('-')
    newDate.setHours(0, 0, 0, 0)
    newDate.setFullYear(Number(years), Number(months) - 1, Number(days))
    return newDate
}

export function addDays(date: Date, days: number) {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
}

export function isSameOrAfter(dateStart: Date, dateEnd: Date) {
    return dateStart.getTime() >= dateEnd.getTime()
}
export function isSame(dateStart: Date, dateEnd: Date) {
    return dateStart.getTime() === dateEnd.getTime()
}

export function isAfter(dateStart: Date, dateEnd: Date) {
    return dateStart.getTime() > dateEnd.getTime()
}

export function monthDiff(dateFrom: Date, dateTo: Date) {
    return dateTo.getMonth() - dateFrom.getMonth() +
        (12 * (dateTo.getFullYear() - dateFrom.getFullYear()))
}
export function isPastDate(date: Date) {
    const tmp = new Date()
    return tmp.getTime() > date.getTime()
}

export function getClockString(date: Date) {
    return `${date.getHours()}:${date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()}`
}

export function parseDate(dateIsoString: string) {
    const d = new Date(dateIsoString)
    return Number.isNaN(d.getTime()) ? null : d
}

export function isValidTime(timeStr: string) {
    if (!timeStr || typeof timeStr !== 'string' || !timeStr.includes(':')) {
        return false
    }
    const [hoursStr, minutesStr] = timeStr.split(':')
    const [hours, minutes] = [Number(hoursStr), Number(minutesStr)]
    if (Number.isNaN(hours) || Number.isNaN(minutes)) {
        return false
    }
    if (hours < 0 || hours > 23 || minutes < 0 || minutes > 45 || minutes % 15 !== 0) {
        return false
    }
    return timeStr
}

export function dayDiff(dateEnd: Date, dateStart: Date) {
    const diffTime =
        dateStart.getTime() - dateEnd.getTime()

    return Math.round(diffTime / (1000 * 3600 * 24))
}

export function constructFromDateTimeStr(dateStr: string, timeStr: string) {
    return setClock(parseDate(dateStr)!, timeStr)
}

export function repairDates(dateStartStr: string, dateEndStr: string, timeStart: string, timeEnd: string) {
    const startTime = isValidTime(timeStart) || '12:00'
    const endTime = isValidTime(timeEnd) || '12:00'
    let start = setClock(parseDate(dateStartStr) || addDays(new Date(), 7), startTime)
    let end = setClock(parseDate(dateEndStr) || addDays(new Date(), 14), endTime)
    const timeQuery = {
        arrival: formatApiDate(start),
        departure: formatApiDate(end),
        arrivalTime: startTime,
        departureTime: endTime
    }
    if (isPastDate(start) && Math.abs(dayDiff(new Date(), start)) <= 1) {
        const newStart = new Date()
        const fixedStart = addMinutes(newStart, 15)
        timeQuery.arrival = formatApiDate(fixedStart)
        timeQuery.arrivalTime = getClockString(fixedStart)
        if (isSame(newStart, end) || isAfter(newStart, end)) {
            timeQuery.departure = formatApiDate(addDays(newStart, 7))
        }
    } else if (isPastDate(start)) {
        const newStart = addDays(new Date(), 7)
        timeQuery.arrival = formatApiDate(newStart)
        timeQuery.departure = formatApiDate(addDays(newStart, 7))
        timeQuery.arrivalTime = '12:00'
        timeQuery.departureTime = '12:00'
    }
    start = constructFromDateTimeStr(timeQuery.arrival, timeQuery.arrivalTime)
    end = constructFromDateTimeStr(timeQuery.departure, timeQuery.departureTime)
    if (isSame(start, end)) {
        const fixedDeparture = addMinutes(start, 15)
        timeQuery.departure = formatApiDate(fixedDeparture)
        timeQuery.departureTime = getClockString(fixedDeparture)
    } else
        if (isAfter(start, end)) {
            if (Math.abs(dayDiff(start, end)) <= 1) {
                const departure = addMinutes(start, 15)
                timeQuery.departure = formatApiDate(departure)
                timeQuery.departureTime = getClockString(departure)
            } else {
                const fixedDeparture = addDays(start, 7)
                timeQuery.departure = formatApiDate(fixedDeparture)
            }
        }

    return timeQuery
}
