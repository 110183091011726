// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';
import dayjs from 'dayjs';

class BffService {
  constructor($config) {
    this.pages = [];
    this.pageContent = [];
    this.services = {};
    /* https://mpl-bff-dot-parkos-production.appspot.com/ */
    this.axiosInstance = axios.create({ baseURL: $config.BFF_BASE_URL });

    this.axiosInstance.interceptors.request.use(
      (request) => {
        request.headers['User-Agent'] = this.getUserAgent();

        return request;
      },
      (error) => {
        console.log('BFF Request Error: ', JSON.stringify(error));
        return error;
      },
    );
    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        console.log('BFF Response error: ', JSON.stringify(error));
        return error;
      },
    );
    this.refreshes = {
      pageContent: false,
      services: false,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  getUserAgent() {
    const appName = 'ParkosMerchantPages';
    const projectName = 'mpl-merchant-pages';
    const { VERSION, ENVIRONMENT } = process.env;

    if (VERSION && ENVIRONMENT) {
      return `${appName}/${VERSION} (+https://${projectName}.${ENVIRONMENT}.parkos.dev/)`;
    }

    return `${appName}/development`;
  }

  // eslint-disable-next-line func-names
  refresh() {
    this.refreshes.pageContent = true;
    this.refreshes.services = true;
  }

  async getPageContent(devtitle) {
    const self = this;
    const res = await self.axiosInstance.get(`pages/${devtitle}/content.json`);
    return res.data;
  }

  async getMerchantContent(id) {
    const self = this;
    const res = await self.axiosInstance.get(`merchants/${id}/content.json`);
    if (res.data && res.data.media) {
      res.data.video = res.data.media.find((element) => element.url.includes('https://www.youtube.com'));
      if (res.data.video) {
        res.data.video = res.data.video.url.replace('embed/', 'watch?v=');
      }
      res.data.media = res.data.media.sort((a, b) => (a.sort > b.sort ? 1 : -1));
    }
    return res.data;
  }

  async getHealthz() {
    const self = this;
    const { data } = await self.axiosInstance.get('healthz');
    return data;
  }

  async getMerchantReviews(id, lang, type, page, pageSize, score) {
    const res = await this.axiosInstance.get(
      `merchants/${id}/reviews-replies.json`,
      {
        params: {
          lang,
          type,
          size: pageSize,
          page,
          score,
        },
      },
    );
    return res.data;
  }

  async getMerchantReviewsV2(id, lang, type, page, pageSize, score) {
    const params = new URLSearchParams();

    if (score) {
      score.split(',').forEach((item) => {
        const [op, val] = item.split(':');
        params.append(`rating[${op}]`, val);
      });
    }

    if (lang) params.append('lang', lang);
    if (type) params.append('merchant.parkingTypes.type[in]', type);
    if (pageSize) params.append('size', pageSize);
    if (page) params.append('page', page);

    const res = await this.axiosInstance.get(
      `merchants/${id}/reviews-replies-v2.json?${params.toString()}`,
    );
    return res.data;
  }

  async getMerchantReviewSummaries(id) {
    const res = await this.axiosInstance.get(`merchants/${id}/review-summaries.json`);
    return res.data;
  }

  async merchantParkingSearch(id, domain, lang, departure, arrival) {
    const self = this;
    departure = dayjs(departure).format('DD-MM-YYYY');
    arrival = dayjs(arrival).format('DD-MM-YYYY');
    const res = await self.axiosInstance.get(`merchants/${id}/search.json`, {
      params: {
        domain,
        lang,
        departure,
        arrival,
      },
    });
    return res.data;
  }

  async locationSearch(payload) {
    try {
      const self = this;
      const res = await self.axiosInstance.get('offers', {
        params: {
          ...payload,
          version: 5,
        },
      });

      return res.data;
    } catch (error) {
      console.error('An error occurred with Axios:', error.message);
      return error;
    }
  }

  async getLanguages() {
    const self = this;
    const res = await self.axiosInstance.get('languages');
    return res.data.data;
  }

  async getTranslations(language) {
    const self = this;
    const res = await self.axiosInstance.get(
      `translations/${language}/merchant`,
    );
    return res.data;
  }

  async getAirports(
    lang,
    limit = 250,
    orderBy = 'locations_content.maintitle',
  ) {
    const self = this;
    const res = await self.axiosInstance.get('airports', {
      params: { lang, limit, orderBy },
    });

    return res.data.data;
  }

  async getAirport(slug, lang) {
    const self = this;
    const airports = await self.getAirports(lang);
    const airport = Array.prototype.find.call(airports, (airport) => airport.slug === slug);

    if (!airport) {
      throw Object.assign(new Error('Airport not found'), { statusCode: 404 });
    }
    return airport;
  }

  async getAirportData(slug, lang) {
    const self = this;
    const airport = await self.getAirport(slug, lang);
    const res = await self.axiosInstance.get(`airports/${airport.id}/details`);
    return res.data;
  }

  async getAirportReviews(slug, lang, limit = 10) {
    const self = this;
    const { id: airport } = await self.getAirport(slug, lang);
    const res = await self.axiosInstance.get('airport/reviews', {
      airport,
      lang,
      limit,
    });
    return res.data;
  }

  async getAirportFaq(slug, lang) {
    const self = this;
    const { id: airport } = await self.getAirport(slug, lang);
    const res = await self.axiosInstance.get(`airports/${airport}/faq`, {
      params: { lang },
    });
    return res.data;
  }

  async getAirportParkings(slug, lang) {
    const self = this;
    const { id: airport } = await self.getAirport(slug, lang);
    const res = await self.axiosInstance.get('parkings', {
      params: { airport, lang },
    });
    return res.data.data;
  }

  async getPageTemplate(slug, lang) {
    const self = this;
    const res = await self.axiosInstance.get('page_template', {
      params: { slug, lang },
    });
    return res.data;
  }

  async getMerchantData(merchantId) {
    const self = this;
    const res = await self.axiosInstance.get(`parkings/${merchantId}`);
    return res.data;
  }

  async getHerflangs(merchantId) {
    const self = this;
    const res = await self.axiosInstance.get(
      `merchant-herflangs/${merchantId}`,
    );
    return res.data;
  }

  getCountryVat = async (countryCode) => {
    const self = this;
    const vat = await self.axiosInstance.get(
      `/get-vat?countryCode=${countryCode}`,
    );
    return vat.data;
  };

  getService = async (service) => {
    const self = this;
    const serviceType = await self.axiosInstance.get(`services/${service}`);
    return serviceType.data;
  };
}

const bffInstances = {};

function getInstance(name, config) {
  if (!(name in bffInstances)) {
    bffInstances[name] = new BffService(config);

    setInterval(() => {
      bffInstances[name].refresh();
    }, 60000);
  }

  return bffInstances[name];
}

// eslint-disable-next-line import/prefer-default-export
export { getInstance };
