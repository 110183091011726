import axios from 'axios';

const axiosInstance = axios.create();

function convertDateFormat(date, time) {
  // Ensure time is in HH:MM format
  const formattedTime = time.length === 4 ? `0${time}` : time;

  // Create a Date object in UTC
  const dateTime = new Date(`${date}T${formattedTime}:00Z`);

  // Check if the date is valid
  if (isNaN(dateTime.getTime())) {
    return 'Invalid date or time format';
  }

  // Format the date and time
  const formattedDateTime = `${dateTime.getUTCFullYear()}-${String(
    dateTime.getUTCMonth() + 1,
  ).padStart(2, '0')}-${String(dateTime.getUTCDate()).padStart(
    2,
    '0',
  )}T${String(dateTime.getUTCHours()).padStart(2, '0')}:${String(
    dateTime.getUTCMinutes(),
  ).padStart(2, '0')}`;

  return formattedDateTime;
}
function getBlinkParkingURl($config, payload) {
  const whitelist = [
    'arrival',
    'arrivalTime',
    'departure',
    'departureTime',
    'location',
    'lang',
  ];
  const version = 'v1';
  const tmpPayload = { ...payload };
  Object.keys(tmpPayload).forEach((key) => {
    if (!whitelist.includes(key)) delete tmpPayload[key];
  });
  const parkingStart = convertDateFormat(
    tmpPayload.arrival,
    tmpPayload.arrivalTime,
  );

  const parkingEnd = convertDateFormat(
    tmpPayload.departure,
    tmpPayload.departureTime,
  );

  const blinkSearchURL = `${$config.BFF_BASE_URL}${version}/offers?location=${tmpPayload.location}&parkingStart=${parkingStart}&parkingEnd=${parkingEnd}&lang=${tmpPayload.lang}`;
  return blinkSearchURL;
}

async function getAirportFee($config, devtitle) {
  try {
    const res = await axiosInstance.get(`airport/${devtitle}/fee`, { baseURL: $config.BFF_BASE_URL });
    return res.data;
  } catch (e) {
    console.error(e);
    return e;
  }
}

const fastSearchApi = {
  getAirportFee,
  getBlinkParkingURl,
};

export default fastSearchApi;
