import { filters } from '~/constants/_fastSearch';
import { formatApiDate } from './dates.ts';
import { yieldToMain } from './utils.js';

export function cloneFilters(filtersObj) {
  const clonedObj = JSON.parse(JSON.stringify(filtersObj));
  const keys = Object.keys(filtersObj);
  keys.forEach((key) => {
    clonedObj[key].forEach((_, i) => {
      clonedObj[key][i].condition = filtersObj[key][i].condition;
    });
  });

  return clonedObj;
}

function chunkingCheckboxes(filtersObj) {
  return {
    parking:
      filtersObj.parking
        .filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    location:
      filtersObj.location
        .filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    carKeys:
      filtersObj.carKeys
        .filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    payOptions:
      filtersObj.payOptions
        .filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    services:
      filtersObj.services
        .filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    returnStation:
      filtersObj?.returnStation
        ?.filter((value) => value.value)
        .map((value) => value.condition) ?? [],
    official:
      filtersObj?.official
        .filter((obj) => obj.value)
        .map((obj) => obj.condition) ?? [],
  };
}

export function filterParkings(parkingsObj, filterObject) {
  const filteredObj = [];
  if (!parkingsObj) return filteredObj;
  const queryFilterObj = Object.fromEntries(Object.entries(chunkingCheckboxes(filterObject)).filter(
    ([, valueArr]) => valueArr && valueArr.length,
  ));
  Object.values(parkingsObj).forEach(async(value) => {
    const filterMap = {};
    Object.keys(queryFilterObj).forEach((filterKey) => {
      const filtersArr = queryFilterObj[filterKey];
      if (filterKey === 'services') {
        if (filtersArr.length > 1) {
          filterMap[filterKey] = filtersArr.some((fn) => fn(value));
        } else if (filtersArr.length === 1) {
          filterMap[filterKey] = filtersArr.every((fn) => fn(value));
        } else {
          filterMap[filterKey] = true;
        }
        return;
      }
      if (filtersArr.length > 1) {
        filterMap[filterKey] = true;
      } else if (filtersArr.length) {
        filterMap[filterKey] = filtersArr.every((fn) => fn(value));
      } else {
        filterMap[filterKey] = true;
      }
    });
    if (Object.values(filterMap).every((e) => e)) {
      filteredObj.push(value);
    }
    await yieldToMain();
  });
  return filteredObj;
}

export function sortParkings(parkingsObj, sortKey, sortValue, isUSDomain) {
  const fallbackSorts = [
    { key: 'price', sort: 1 },
    { key: 'sort_reviews', sort: -1 },
    { key: 'reviews_amount', sort: -1 },
    { key: 'sort_distance', sort: 1 },
    { key: 'prs', sort: -1 },
  ];
  switch (sortKey) {
    case 'distance':
      sortKey = 'sort_distance';
      break;
    case 'reviews':
      sortKey = 'sort_reviews';
      break;
    case 'prs':
      sortKey = 'prs';
      break;
    default:
      sortKey = 'price';
      break;
  }

  fallbackSorts.splice(
    fallbackSorts.findIndex((e) => e.key === sortKey),
    1,
  );

  const sortWithValue = sortValue === 'asc' ? [1, -1] : [-1, 1];
  return Object.keys(parkingsObj)
    .sort((a, b) => {
      const parkingA = parkingsObj[a];
      const parkingB = parkingsObj[b];
      const sortValueA = +(
        (parseFloat(parkingA[sortKey]))
        / (sortKey === 'price' && isUSDomain ? parkingA.days : 1)
      ).toFixed(8) || 0;
      const sortValueB = +(
        (parseFloat(parkingB[sortKey]))
        / (sortKey === 'price' && isUSDomain ? parkingB.days : 1)
      ).toFixed(8) || 0;

      if (sortValueA > sortValueB) {
        return sortWithValue[0];
      }
      if (sortValueA < sortValueB) {
        return sortWithValue[1];
      }

      for (let i = 0; i < fallbackSorts.length; i += 1) {
        const obj = fallbackSorts[i];
        let valueA = 0;
        let valueB = 0;
        switch (obj.key) {
          case 'reviews_amount':
            valueA = parkingA.sort_reviews === 0 ? 0 : parkingA.review?.count;
            valueB = parkingB.sort_reviews === 0 ? 0 : parkingB.review?.count;
            break;
          default:
            valueA = parseFloat(parkingA[obj.key]).toFixed(2);
            valueB = parseFloat(parkingB[obj.key]).toFixed(2);
            break;
        }
        if (+valueA > +valueB) {
          return obj.sort;
        }
        if (+valueA < +valueB) {
          return -obj.sort;
        }
      }

      return sortWithValue[1];
    })
    .map((key) => parkingsObj[key]);
}

export function generateFilterObject(query) {
  const temp = cloneFilters(filters);
  Object.entries(temp).forEach(([key, arr]) => arr.forEach((value, idx) => {
    temp[key][idx].value = !!query?.[value.param];
  }));
  return temp;
}

function addDays(date, days) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
// returns adjusted dates when any restriction should occur
export function isPastDate(date, doNotResetTime = false) {
  // Ensure we're working with Date objects
  const inputDate = date instanceof Date ? date : new Date(date);
  const today = new Date();

  // Set both dates to midnight for date-only comparison
  if (!doNotResetTime) {
    inputDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);
  }
  // Compare dates
  return inputDate < today;
}

// returns adjusted dates when any restriction should occur
export function inferSearchDates(dateStart, dateEnd) {
  const range = {
    start: dateStart,
    end: dateEnd,
  };
  if (!dateEnd && dateStart) {
    // if only the arrival is selected, departure should be seven days from arrival.
    range.end = addDays(range.start, 7);
  } else if (dateEnd && !dateStart) {
    // if only the departure is selected, arrival should be MAX(now, seven days earlier from arrival)
    range.start = addDays(range.end, -7);
    if (new Date().getTime() > range.start.getTime()) {
      range.start = new Date();
    }
  } else if (!dateEnd && !dateStart) {
    // if none is selected, arrival should be seven days from now, departure should be seven days from arrival
    range.start = addDays(new Date(), 8);
    range.end = addDays(range.start, 7);
  } else if (dateStart && dateEnd) {
    if (isPastDate(range.start) || isPastDate(range.end)) {
      range.start = addDays(new Date(), 8);
      range.end = addDays(range.start, 7);
    }
    // if already set, make sure greater departure date is set
    if (dateStart.getTime() > dateEnd.getTime()) {
      range.start = addDays(new Date(), 8);
      range.end = addDays(range.start, 7);
    }
  }

  return range;
}

function getValidatedTimeQueryParam(timeQuery) {
  if (!timeQuery || typeof timeQuery !== 'string' || !timeQuery.length) {
    return '12:00';
  }
  const [hours, minutes] = timeQuery.split(':');
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(hours || undefined) || isNaN(minutes || undefined)) {
    return '12:00';
  }
  const date = new Date(null, null, null, hours, minutes);
  return `${date.getHours()}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;
}

export function getRepairedQuery(
  brokenQuery,
  location,
  defaultSortF = 'price',
  defaultSortW = 'asc',
) {
  const query = { ...brokenQuery };
  query.location = query.location || location;

  // accept NON-ISO
  if (query.arrival) {
    const splitted = query.arrival.split('-');
    if (splitted[2] && splitted[2].length === 4) {
      query.arrival = splitted.reverse().join('-');
    }
  }
  if (query.departure) {
    const splitted = query.departure.split('-');
    if (splitted[2] && splitted[2].length === 4) {
      query.departure = splitted.reverse().join('-');
    }
  }

  const range = inferSearchDates(
    Date.parse(query.arrival) ? new Date(query.arrival) : null,
    Date.parse(query.departure) ? new Date(query.departure) : null,
  );
  const strDateStart = formatApiDate(range.start);
  const strDateEnd = formatApiDate(range.end);
  // setting the query in case the user remove the query string
  query.arrival = strDateStart;
  query.departure = strDateEnd;
  query.arrivalTime = getValidatedTimeQueryParam(query.arrivalTime);
  query.departureTime = getValidatedTimeQueryParam(query.departureTime);
  const dateStartFull = new Date(...query.arrival.split('-'), ...query.arrivalTime.split(':'));
  const dateEndFull = new Date(...query.departure.split('-'), ...query.departureTime.split(':'));
  if (dateStartFull.getTime() > dateEndFull.getTime()) {
    query.departure = formatApiDate(new Date(dateStartFull.setDate(dateStartFull.getDate() + 1)));
  }

  query.sort_f = query.sort_f || defaultSortF;
  query.sort_w = query.sort_w || defaultSortW;

  query.version = 5;
  return query;
}
