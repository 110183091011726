import fastSearchApi from '~/services/fastSearchApi';

const hashCode = (str) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    hash = Math.imul(31, hash) + str.charCodeAt(i) | 0;
  }
  return `${hash}`;
};

export const state = () => ({
  parkingsOffers: {},
  clickedListing: null,
  selectedParking: null,
  offerKeys: {},
  isLoading: true,
  listings: {},
  firstLoad: null,
  pushDataLayer: true,
});

export const actions = {
  async getOffersV2({ commit, dispatch, state }, {
    airport, arrivalTime, departureTime, arrival, departure, lang, parkingIds, clickedListing, route, initial,
  }) {
    const blinkUrl = fastSearchApi.getBlinkParkingURl(this.$config, {
      location: airport.slug,
      arrivalTime,
      departureTime,
      arrival,
      departure,
      lang,
    });
    commit('SET_LISTINGS', {});

    const SSE = new EventSource(blinkUrl);
    commit('SET_LOADING', true);
    SSE.addEventListener('first-response', async (e) => {
      const listings = JSON.parse(e.data);
      // Filter offers by the merchant ID
      let filteredAvailable = listings.available ? listings.available.filter((offer) => offer.merchant.id === parkingIds) : [];
      const filteredUnavailable = listings.unavailable ? listings.unavailable.filter((offer) => offer.merchant.id === parkingIds) : [];

      if (clickedListing && !state.firstLoad) {
        const clickedListingPrice = filteredAvailable.find((offer) => {
          const offerString = `${offer.parking_type}${offer.roof ? 'indoor' : 'outdoor'}`;
          return offerString === clickedListing;
        })?.price;
        if (clickedListingPrice) {
        // remove from filteredAvailable
          filteredAvailable = filteredAvailable.filter((offer) => offer.price >= clickedListingPrice);
        }

        commit('SET_FIRST_LOAD', clickedListing);
      } else if (state.firstLoad) {
        const clickedListingPrice = filteredAvailable.find((offer) => {
          const offerString = `${offer.parking_type}${offer.roof ? 'indoor' : 'outdoor'}`;
          return offerString === state.firstLoad;
        })?.price;
        if (clickedListingPrice) {
          // remove from filteredAvailable
          filteredAvailable = filteredAvailable.filter((offer) => offer.price >= clickedListingPrice);
        }
      } else {
        const options = Object.values(filteredAvailable);

        if (options.length === 0) return; // Ensure there are options available

        const cheapestOption = options.reduce((cheapest, option) => (!cheapest || option.price < cheapest.price ? option : cheapest));

        commit('SET_FIRST_LOAD', `${cheapestOption.parking_type}${cheapestOption.roof ? 'indoor' : 'outdoor'}`);
      }

      // Convert the filteredAvailable array to an object format
      const availableOffers = filteredAvailable.reduce((acc, offer) => {
        const key = `${offer.merchant.slug}-${offer.parking_type}${offer.roof ? 'indoor' : 'outdoor'}`;
        acc[key] = offer;
        return acc;
      }, {});

      // Convert the filteredUnavailable array to an object format
      const unavailableOffers = filteredUnavailable.reduce((acc, offer) => {
        const key = `${offer.merchant.slug}-${offer.parking_type}${offer.roof ? 'indoor' : 'outdoor'}`;
        acc[key] = offer;
        return acc;
      }, {});
      // Update state with the transformed offers
      commit('SET_LISTINGS', {
        available: availableOffers,
        unavailable: unavailableOffers,
      });

      if (
        !state.listings
        || !state.listings.available
        || !Object.keys(state.listings.available).length
      ) {
        await dispatch('setSelectedParking', {
          initial: false,
          initialParking: null,
          route,
        });
        commit('SET_LOADING', false);

        if (state.listings.unavailable) {
          commit('SET_PARKINGS', {
            available: [],
            unavailable: state.listings?.unavailable || [],
          });
        } else {
          commit('SET_PARKINGS', {
            available: [],
            unavailable: [],
          });
        }
      }

      await commit('SET_PARKINGS', {
        available: state.listings.available,
        unavailable: state.listings.unavailable,
      });
      await dispatch('setParkings', { v2: true });
      await dispatch('selectParking', { id: clickedListing, route });

      if (state.pushDataLayer) {
        setTimeout(() => {
          dataLayer.push({
            event: 'experiment_data',
            dl_experiment_id: 'BE-1403',
            dl_experiment_variation: 'b',
            dl_experiment_action: 'last_known_offer',
            dl_experiment_value:
     `{'${state.selectedParking?.merchant?.id}', '${arrival}', '${arrivalTime}', '${departure}', '${departureTime}', '${state.selectedParking?.price}'}`,
          });
        });
        await commit('SET_PUSHDATALAYER', false);
      }

      if (state.listings.pending?.length) {
        state.parkingsOffers.available = [
          ...state.parkingsOffers.available,
          ...state.listings.pending,
        ];

        state.listings.pending.forEach((list, idx) => {
          SSE.addEventListener(list.liveApiResponseToken, async (pendingEvent) => {
            const updatedParking = JSON.parse(pendingEvent.data);
            const availableValues = updatedParking.available;

            if (availableValues.length > 0) {
              setTimeout(() => {
                const finalValue = availableValues[0];

                state.parkingsOffers.available = state.parkingsOffers.available.map((obj) => {
                  if (obj.liveApiResponseToken === list.liveApiResponseToken) return finalValue;
                  return obj;
                });
                state.listings.available = state.parkingsOffers.available;
              }, 200 * idx);
            } else {
              setTimeout(() => {
                const finalValue = updatedParking.unavailable[0];

                state.parkingsOffers.available = state.parkingsOffers.available.filter(
                  (obj) => obj.liveApiResponseToken !== list.liveApiResponseToken,
                );
                state.parkingsOffers.unavailable.push(finalValue);
                state.listings.available = state.parkingsOffers.available;
                state.listings.unavailable = state.parkingsOffers.unavailable;
              }, 200 * idx);
            }
            await dispatch('setParkings', { v2: true });

            await dispatch('selectParking', { id: clickedListing, route });
          });
        });
      }

      commit('SET_LOADING', false);
    });

    SSE.addEventListener('open', (e) => {
      console.log('Connection started.');
    });

    SSE.addEventListener('error', (e) => {
      SSE.close();
      commit('SET_LOADING', false);
      console.log('Connection closed.');
    });
  },
  getOffers(_, {
    airport, arrivalTime, departureTime, arrival, departure, lang, parkingIds,
  }) {
    return this.$axios({
      method: 'get',
      baseURL: this.$config.BFF_BASE_URL,
      url: 'offers',
      params: {
        location: airport.slug,
        arrivalTime,
        departureTime,
        arrival,
        departure,
        lang,
        parkingIds: [parkingIds],
        blink: true,
      },
    });
  },
  async setParkings({ state, dispatch, commit }, { parkingsOffers, v2 = false }) {
    if (!v2) commit('SET_PARKINGS', parkingsOffers);
    if (state.parkingsOffers?.available) {
      const size = Object.keys(state.parkingsOffers?.available).length;
      if (size) {
        Object.keys(state.parkingsOffers?.available).map(async (offer) => {
          await dispatch('getVForKey', { offer: state.parkingsOffers?.available[offer] }).then((id) => {
            commit('SET_OFFER_KEYS', { offer, id });
          });
        });
      } else {
        commit('SET_OFFER_KEYS', { offer: [], id: null });
      }
    }
  },
  async setSelectedParking({ dispatch, commit, state }, { initial, initialParking, route }) {
    if (!route) return;

    if (initial && initialParking) {
      commit('SET_SELECTED_PARKING', initialParking);
    } else if (
      state.parkingsOffers
      && state.parkingsOffers?.available
      && Object.values(state.parkingsOffers?.available).length
    ) {
      const valletParkingFound = Object.values(state.parkingsOffers?.available).find(
        (parking) => parking.parking_type === 'valet',
      );
      if (valletParkingFound) {
        commit('SET_SELECTED_PARKING', valletParkingFound);
      } else {
        commit('SET_SELECTED_PARKING', Object.values(state.parkingsOffers?.available)[0]);
      }
      const clickedListing = await dispatch('getVForKey', { offer: state.selectedParking });
      if (state.clickedListing !== clickedListing) {
        commit('SET_CLICKED_LISTING', clickedListing);

        this.$router.replace({ query: { ...route.query, clickedListing } }).catch(() => {});
      }
    } else {
      commit('SET_SELECTED_PARKING', null);
      commit('SET_CLICKED_LISTING', null);
    }
  },
  async selectParking({ commit, dispatch, state }, { id, route }) {
    if ((state.clickedListing && state.clickedListing === id) || !state.parkingsOffers?.available) return;
    let foundParking = null;
    const bar = new Promise((resolve, reject) => {
      Object.values(state.parkingsOffers?.available).forEach(async (parking, index, array) => {
        const generatedId = await dispatch('getVForKey', { offer: parking });
        if (generatedId === id) {
          foundParking = parking;
          resolve();
        }
        if (index === array.length - 1) resolve();
      });
    });
    bar.then(async () => {
      if (foundParking) {
        commit('SET_CLICKED_LISTING', id);
        this.$router.replace({ query: { ...route.query, clickedListing: id } }).catch(() => {});
        await dispatch('setSelectedParking', { initial: true, initialParking: foundParking, route });
      } else {
        await dispatch('setSelectedParking', { initial: true, initialParking: null, route });
      }
    });
  },
  getParkingType(_, { parkingType, roof, short = false }) {
    if (short) {
      if (parkingType === 'shuttle') {
        return roof ? 'si' : 'so';
      }
      if (parkingType === 'valet') {
        return roof ? 'vi' : 'vo';
      }
    }

    if (parkingType === 'shuttle') {
      return roof ? 'indoor' : 'outdoor';
    }
    if (parkingType === 'valet') {
      return roof ? 'valetindoor' : 'valet';
    }
  },
  async getVForKey({ dispatch }, { offer }) {
    if (!offer) {
      return '';
    }

    return `${offer.parking_type}${offer.roof ? 'indoor' : 'outdoor'}`;
  },
};

export const mutations = {
  SET_PUSHDATALAYER(state, pushDataLayer) {
    state.pushDataLayer = pushDataLayer;
  },
  SET_FIRST_LOAD(state, firstLoad) {
    state.firstLoad = firstLoad;
  },
  SET_LISTINGS(state, listings) {
    state.listings = listings;
  },
  SET_PARKINGS(state, parkingsOffers) {
    state.parkingsOffers = parkingsOffers;
  },
  SET_SELECTED_PARKING(state, selectedParking) {
    state.selectedParking = selectedParking;
  },
  SET_CLICKED_LISTING(state, clickedListing) {
    state.clickedListing = clickedListing;
  },
  SET_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_OFFER_KEYS(state, { offer, id }) {
    if (id && offer) state.offerKeys[offer] = id;
    else state.offerKeys = offer;
  },
};
