import {
  generateFilterObject, filterParkings, sortParkings, cloneFilters,
} from '~/helpers/search';
import { filters } from '~/constants/_fastSearch';
import { yieldToMain } from '~/helpers/utils';

function assignDataForDABucket(query, commit) {
  const matchedFilters = Object.values(cloneFilters(filters)).flat(1).map((e) => ([e.param, query[e.param] === e.ssf.split(':')[1] ? 1 : 0]));
  commit('bucket/update', { key: 'filter_state', value: Object.fromEntries(matchedFilters) }, { root: true });
  const diff = Math.abs((new Date(query.departure)).getTime() - (new Date(query.arrival)).getTime())
    / 1000
    / 60
    / 60
    / 24;
  commit('bucket/update', {
    key: 'number_of_days',
    value: Math.floor(diff) + 1,
  }, { root: true });
}

export const state = () => ({
  query: {},
  results: {
    availableResults: [],
    unavailableResults: [],
  },
  checkboxFilter: [],
  isUSDomain: false,
  finderSelected: null,
  priceRange: { min: 0, max: Number.MAX_SAFE_INTEGER },
});

export const getters = {
  cheapestParking(state) {
    return sortParkings([...state.results.availableResults], 'price', 'asc')[0];
  },
  bestRatedParking(state) {
    return [...state.results.availableResults].sort((parkingA, parkingB) => {
      const ratingComparison = parseFloat(parseFloat(parkingB.sort_reviews).toFixed(1)) - parseFloat(parseFloat(parkingA.sort_reviews).toFixed(1));

      if (ratingComparison === 0) {
        // If ratings are the same, prioritize the parking with more reviews
        return parseFloat(parkingB.review.count) - parseFloat(parkingA.review.count);
      }

      return ratingComparison;
    })[0];
  },
  mostReviewedParking(state) {
    return [...state.results.availableResults].sort((parkingA, parkingB) => (parseFloat(parkingA.review.count)
      < parseFloat(parkingB.review.count) ? 1 : -1))[0];
  },

};

export const mutations = {
  selectFinderListing(state, payload) {
    state.finderSelected = payload;
  },
  updateQueryOverride(state, payload) {
    state.query = { ...payload };
  },
  updateQuery(state, payload) {
    state.query = { ...state.query, ...payload };
  },
  updateFiltersFromSearch(state, payload) {
    if (!payload) {
      return;
    }
    const temp = state.query;
    Object.values(payload)
      .flat(1)
      .forEach((value) => {
        const [key, val] = value.ssf.split(':');
        if (!value.value) {
          delete temp[key];
        } else {
          temp[key] = val;
        }
      });
    state.query = temp;
  },
  updateResults(state, payload) {
    state.results[payload.key] = payload.value;
  },
  updateCheckboxFilter(state, payload) {
    state.checkboxFilter = payload;
  },
  priceFilter(state, payload) {
    state.priceRange.max = payload.max;
    state.priceRange.min = payload.min;
    state.priceRange.enabled = true;
  },
};

export const actions = {
  scrollToSearch() {
  },
  async filterAndSort({ state, commit }, { listings }) {
    const q = state.query;
    const filterObj = generateFilterObject(q);
    assignDataForDABucket(q, commit);
    state.results.availableResults = sortParkings(filterParkings(listings.available, filterObj), q.sort_f || 'price', q.sort_w || 'asc', state.isUSDomain);
    await yieldToMain();
    state.results.unavailableResults = sortParkings(filterParkings(listings.unavailable, filterObj), q.sort_f || 'price', q.sort_w || 'asc', state.isUSDomain);
    await yieldToMain();
    if (state.priceRange.enabled) {
      state.results.availableResults = state.results.availableResults.filter((a) => {
        if (Number(a.price) >= state.priceRange.min && Number(a.price) <= state.priceRange.max) {
          return true;
        }
        return false;
      });
    }
    // DA Bucket
    commit('bucket/update', { key: 'merchant_amount_available', value: state.results?.availableResults?.length || 0 }, { root: true });
    commit('bucket/update', { key: 'merchant_amount_unavailable', value: state.results.unavailableResults.length || 0 }, { root: true });

    let cheapest = null;
    let mostExpensive = null;
    state.results?.availableResults?.forEach((offer) => {
      if (!cheapest || cheapest.price > offer.price) {
        cheapest = offer;
      }
      if (!mostExpensive || mostExpensive.price < offer.price) {
        mostExpensive = offer;
      }
    });
    if (cheapest) {
      commit('bucket/update', { key: 'lowest_price', value: cheapest?.price }, { root: true });
    }
    if (mostExpensive) {
      commit('bucket/update', { key: 'highest_price', value: mostExpensive?.price }, { root: true });
    }
  },
};
