<template>
  <header class="hidden md:block relative leading-normal">
    <div :class="{hidden: scrollTop<80}" class="bottom-shadow bg-white z-50 fixed h-[80px] w-full top-0 ">
      <section
        class="container px-10 z-40 md:static w-full h-full flex items-center justify-between"
      >
        <div class="flex">
          <div
            v-for="(tab, index) in tabsContent"
            :key="index"
          >
            <div
              role="button"
              tabindex="0"
              class="p-1"
              @keypress="activate(index, tab.title)"
              @click="activate(index, tab.title)"
            >
              <h2 class="text-center whitespace-nowrap">
                {{ tab.title }}
              </h2>
            </div>
          </div>
        </div>
        <div>
          <div v-if="isLoading" class="order-4 grow-0 self-center w-full md:w-auto flex ">
            <div class="animate-pulse">
              <!-- Results Grid -->
              <div class="bg-white rounded-lg shadow text-left h-full flex flex-col justify-between">
                <div class="flex items-center">
                  <div class="h-4 bg-gray-200 mr-2 mb-[2px] rounded w-23" />
                  <div class="h-4 bg-gray-200 mb-[2px] rounded w-23" />
                </div>

                <div class="space-y-2 ml-auto mr-2">
                  <div class="h-4 bg-gray-200 rounded w-52" />
                </div>
              </div>
            </div>
            <button
              class="px-10 w-max md:w-auto capitalize-first text-[14px] font-cocogoose cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded bg-gray-400 leading-none py-[11px] max-h-[46px] text-center text-white"
            >
              <svg class="animate-spin h-6 w-6 text-white" viewBox="0 0 24 24" fill="none">
                <circle cx="12" cy="12" r="10" stroke="#fff" stroke-width="4" />
                <path
                  class="opacity-75"
                  fill="#206bf6"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                />
              </svg>
            </button>
          </div>
          <div
            v-else-if="selectedParking"
            class="items-center order-4 grow-0 self-center w-full md:w-auto flex"
          >
            <div>
              <div class="w-full flex items-center mb-[5px]">
                <h4 class="text-[20px] leading-none font-cocogoose text-right mr-[5px] mb-0">
                  {{ price }}
                </h4>
                <h5 class="mr-2 font-sans text-[14px] text-manatee leading-normal truncate m-0">
                  {{ priceLabel }}
                </h5>
              </div>
              <p class="text-[14px] mr-2 whitespace-nowrap underline leading-none" @click="pickDate">
                {{ mobileFormatedDate }}
              </p>
            </div>
            <button
              class="px-5 w-max md:w-auto capitalize-first max-h-[48px] text-[14px] font-cocogoose cursor-pointer overflow-hidden text-ellipsis whitespace-nowrap rounded bg-orange-500 leading-none py-4 text-center text-white hover:bg-blaze-orange-500"
              @click.stop.prevent="redirectToListingPage"
            >
              {{ $i18n('general.book') }}
            </button>
          </div>
          <div v-else class="order-4 grow-0 self-center  text-center h-[46px] w-full md:w-auto flex">
            <p class="text-base font-cocogoose">
              {{ $i18n('search.no_results_found_title') }}
            </p>
            <p class="text-[14px] underline" @click="pickDate">
              {{ mobileFormatedDate }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </header>
</template>

<script>
import dayjs from 'dayjs';

export default {
  props: {},
  data() {
    return {
      scrollTop: 0,
    };
  },
  computed: {
    tabsContent() {
      return [
        {
          title: this.$i18n('templates.general-information'),
        },
        {
          title: this.$i18n('templates.safety'),
        },
        {
          title: this.$i18n('templates.facilities'),
        },
        {
          title: this.$i18n('templates.reachability'),
        },
        {
          title: this.$i18n('merchant.location'),
        },
      ];
    },
    language() {
      return this.$store.state.language;
    },
    storeDates() {
      return this.$store.state.dates;
    },
    selectedParking() {
      return this.$store.state.offers.selectedParking;
    },
    isLoading() {
      return this.$store.state.offers.isLoading;
    },
    priceLabel() {
      if (this.language.domain === 'parkos.com') {
        return this.$i18n('templates.price-per-day');
      }
      const days = this.selectedParking.days || this.selectedParking.priceGroupCatalogueCalculatedDays;

      return this.$i18n('search.price-days-parking-short', { days }).replaceAll(':', '');
    },
    mobileFormatedDate() {
      return `${this.formatDateShort(this.storeDates.arrival || new Date())}\xA0${this.formatTime(
        this.storeDates.arrivalTime || '12:00',
      )}\xA0\xA0—\xA0\xA0${this.formatDateShort(this.storeDates.departure || new Date())}\xA0${this.formatTime(
        this.storeDates.departureTime,
      )}`;
    },
    price() {
      if (!this.selectedParking) return '';
      const { currency } = this.selectedParking;
      let { price } = this.selectedParking;
      if (this.language.domain === 'parkos.com') {
        price /= this.selectedParking.days;
      }
      return new Intl.NumberFormat(this.language.lang, {
        currency,
        style: 'currency',
      })
        .format(price)
        .replaceAll(/\s/g, '');
    },
  },
  mounted() {
    this.scrollTop = window.scrollY;
    document.addEventListener('scroll', this.scrollCheck);
  },
  methods: {
    activate(index, title) {
      this.$emit('activate', index, title);
    },
    screens(rules, defaultValue) {
      if (process.server) {
        return defaultValue;
      }
      return this.$screens(rules, defaultValue);
    },
    scrollCheck() {
      this.scrollTop = window.scrollY;
    },
    redirectToListingPage() {
      const url = new URL(window.location);
      const formData = new FormData();
      formData.append('parking', this.selectedParking.merchant.id);
      formData.append(
        'parkingtype',
        this.$getParkingType(this.selectedParking.parking_type, this.selectedParking.roof),
      );
      formData.delete('location');
      formData.set('arrival', dayjs(this.$store.state.dates.arrival).format('YYYY-MM-DD'));
      formData.set('arrivalTime', this.$store.state.dates.arrivalTime);
      formData.set('departure', dayjs(this.$store.state.dates.departure).format('YYYY-MM-DD'));
      formData.set('departureTime', this.$store.state.dates.departureTime);
      const query = new URLSearchParams(formData.entries());
      url.pathname = `${this.$i18n('templates.reserve-slug')}/`;
      url.search = query.toString();
      window.location.href = url.toString();
    },
    formatDateShort(date) {
      if (!date) {
        return this.placeholder;
      }
      const options = { day: '2-digit', month: 'short' };
      return new Date(date).toLocaleDateString(this.formatLangForDate(this.language.lang), options);
    },
    formatLangForDate(lang) {
      if (lang === 'nl-be' || lang === 'en-eu') {
        return lang === 'nl-be' ? 'nl' : 'de';
      }
      return lang;
    },
    formatTime(time) {
      if (!time) {
        return `12:00 ${this.language.lang === 'en-us' ? ' PM' : ''}`;
      }
      const timeParsed = time instanceof Date ? time : this.convertTimeStrToDate(time);
      let hours = timeParsed.getHours();
      let minutes = timeParsed.getMinutes();
      if (minutes < 10) {
        minutes = `0${minutes}`;
      }
      let meridiem = '';
      if (this.language.lang === 'en-us') {
        meridiem = ' AM';
        if (hours > 12) {
          meridiem = ' PM';
          hours -= 12;
        } else if (hours === 12) {
          meridiem = ' PM';
        }
      }
      return `${hours}:${minutes}${meridiem}`;
    },
    convertTimeStrToDate(timeStr) {
      const time = (typeof timeStr !== 'string' ? '12:00' : timeStr) || '12:00';
      const [hours, minutes] = time.split(':');
      if (!hours || !minutes) {
        return new Date(2012, 1, 1, 12, 0);
      }
      const d = new Date();
      d.setHours(hours);
      d.setMinutes(minutes);
      return d;
    },
    pickDate() {
      this.$emit('pickDate');
    },
    emitFetchParking() {
      this.$emit('onfetchParking');
    },
  },
};
</script>

<style scoped>
.bottom-shadow{
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.2);
}
</style>
